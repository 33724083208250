<template>
  <el-row type="flex">
    <el-button
      v-if="showEdit"
      size="mini"
      type="info"
      plain
      icon="el-icon-edit-outline"
      @click="onEdit"
    >
      编辑
    </el-button>
    <el-button
      v-if="showDel"
      size="mini"
      icon="el-icon-delete"
      type="info"
      plain
      @click="onDel"
    >
      删除
    </el-button>
    <el-button
      v-if="showMove"
      size="mini"
      icon="el-icon-upload2"
      type="info"
      plain
      @click="onMoveUp"
    >
      上移
    </el-button>
    <el-button
      v-if="showMove"
      size="mini"
      icon="el-icon-download"
      plain
      type="info"
      @click="onMoveDown"
    >
      下移
    </el-button>
  </el-row>
</template>
<script>
export default {
  name: 'ExamOptAction',
  props: {
    showEdit: {
      type: Boolean,
      default: true
    },
    showDel: {
      type: Boolean,
      default: true
    },
    showMove: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    onEdit() {
      this.$emit('onEdit')
    },
    onDel() {
      this.$emit('onDel')
    },
    onMoveUp() {
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      this.$emit('onMoveDown')
    }
  }
}
</script>
