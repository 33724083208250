var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.isMobile && !_vm.isLandscape)?_c('div',{staticClass:"layout-mobile"},[_c('div',{style:({ '--real-sidebar-width': _vm.realSidebarWidth }),attrs:{"id":"app-main"}},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"header-container"},[_c('el-menu',{attrs:{"default-active":_vm.$route.meta.activeMenu || _vm.$route.path,"mode":"horizontal","unique-opened":""}},[_vm._l((_vm.$store.getters['menu/mobileSidebarRoutes']),function(route){return [(route.meta && route.meta.sidebar !== false)?_c('TopbarItem',{key:route.path,staticClass:"topbar-item",attrs:{"item":route,"base-path":route.path}}):_vm._e()]})],2),_c('MobileUserMenu')],1)]),_c('div',{staticClass:"wrapper-layout"},[_c('div',{staticClass:"main"},[_c('transition',{attrs:{"name":"main","mode":"out-in"}},[(_vm.isRouterAlive)?_c('keep-alive',{attrs:{"include":_vm.$store.state.keepAlive.list}},[_c('RouterView',{key:_vm.$route.path})],1):_vm._e()],1)],1)])]),_c('novice-strategy',{model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}})],1):_c('div',{staticClass:"layout-pc"},[_c('div',{style:({
        '--real-sidebar-width': _vm.realSidebarWidth,
        'padding-top': _vm.paddingTop
      }),attrs:{"id":"app-main"}},[(!_vm.isMobile)?_c('header',{staticClass:"header"},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"main"},[_c('Logo',{class:{
                'sidebar-logo-bg': true,
                shadow: _vm.sidebarScrollTop
              },attrs:{"show-logo":true,"collapse":_vm.collapse}}),_c('div',{staticClass:"owner-title"},[_vm._v(" "+_vm._s(_vm.orgName)+" ")])],1),_c('UserMenu',{attrs:{"name":_vm.name},on:{"open-guide-mode":_vm.onOpenGuideMode}})],1)]):_vm._e(),_c('div',{staticClass:"wrapper-layout"},[_c('div',{staticClass:"show sidebar-container"},[_c('div',{staticClass:"sub-sidebar-container",on:{"scroll":_vm.onSidebarScroll}},[_c('el-menu',{staticClass:"menu",attrs:{"unique-opened":"","collapse-transition":true,"collapse":!(_vm.collapse === 'expand'),"default-active":_vm.$route.meta.activeMenu || _vm.$route.path}},[_vm._l((_vm.$store.getters['menu/sidebarRoutes']),function(route){return [(route.meta && route.meta.sidebar !== false)?_c('SidebarItem',{key:route.path,attrs:{"item":route,"base-path":route.path}}):_vm._e()]}),(_vm.isMobile && _vm.isLandscape)?[_c('SidebarUserMenu')]:_vm._e()],2)],1),_c('div',{class:`sidebar-control ${_vm.collapse}`,on:{"click":function($event){_vm.collapse = _vm.collapse === 'expand' ? '' : 'expand'}}},[_c('svg-icon',{staticClass:"icon",attrs:{"name":`sidebar-control${_vm.collapse ? '-' + _vm.collapse : ''}`}}),(_vm.collapse === 'expand')?_c('span',[_vm._v("收起菜单")]):_vm._e()],1)]),_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"main"},[_c('transition',{attrs:{"name":"main","mode":"out-in"}},[(_vm.isRouterAlive)?_c('keep-alive',{attrs:{"include":_vm.$store.state.keepAlive.list}},[_c('RouterView',{key:_vm.$route.path})],1):_vm._e()],1)],1)])]),_c('el-backtop',{staticStyle:{"z-index":"11"},attrs:{"right":5,"bottom":5,"title":"回到顶部"}})],1),_c('novice-strategy',{model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }