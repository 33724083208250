<template>
  <div class="exam-wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <div>
      <el-row type="flex">
        <span v-if="isEdit" class="little-title">题目：</span>
        <el-autocomplete
          v-if="isEdit"
          ref="titleInput"
          :value="title | textFilter"
          type="textarea"
          :rows="9"
          :debounce="300"
          :disabled="isUsed"
          placeholder="【填空题】请输入题目"
          :maxlength="400"
          :trigger-on-focus="false"
          show-word-limit
          class="input-sze"
          style="padding-top: 0; width: 100%;"
          :fetch-suggestions="querySearchAsync"
          @select="handleSelect"
          @input="onChange"
        />
        <div v-else class="exam-blank-fill-wrapper">
          {{ index }}. {{ title | textFilter }}
        </div>
      </el-row>
      <div
        v-if="!isEdit"
        v-safe-html="item.examRichTextContent"
        class="rich-content"
      />
      <el-row v-if="isEdit" type="flex" class="flex-end">
        <span class="tips">提示：填空用连续六个下划线"_"表示</span>
        <ksh-button
          type="text"
          :class="{ active: showRichText }"
          :disabled="isUsed"
          @click="onAddFill"
        >
          插入填空符
        </ksh-button>
      </el-row>
      <el-row v-if="isEdit" type="flex" class="mgn-b10 mgn-t10">
        <span class="little-title">答题设置：</span>
        <div>
          <el-checkbox v-model="isOrder" :disabled="isUsed && isPreSet">
            <span class="setting">答案不分顺序</span>
          </el-checkbox>
          <el-checkbox
            v-model="examAnswerSettingBO.isIgnoreCase"
            :disabled="isUsed && isPreSet"
          >
            <span class="setting">忽略大小写</span>
          </el-checkbox>
          <span class="tips">若一个空有多个参考答案，请添加同义词，匹配任意一个均为正确</span>
        </div>
      </el-row>

      <el-row
        v-for="(v, i) in keyWord"
        :key="i"
        type="flex"
        align="middle"
        class="exam-answer-wrapper"
        :style="`margin-left: ${isEdit ? '' : '13px'}`"
      >
        <template v-if="isEdit">
          <span
            class="little-title"
            style="padding-right: 5px;"
          >第{{ i + 1 }}空答案</span>
          <div class="word-border">
            <el-tag
              v-for="(tag, ind) in v.value"
              :key="tag + ind"
              type="info"
              :closable="!isUsed"
              :disable-transitions="false"
              @close="handleClose(v, tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-show="v.isEditor"
              :ref="'saveTagInput' + i"
              v-model="inputValue"
              class="input-new-tag"
              size="small"
              @keyup.enter.native="handleInputConfirm(v)"
              @blur="handleInputConfirm(v)"
            />
            <el-button
              v-show="!v.isEditor && v.value.length < 10"
              class="button-new-tag"
              size="mini"
              :disabled="isPreSet"
              @click="showInput(v, i)"
            >
              + 添加{{ v.value.length ? '同义词' : '答案' }}
            </el-button>
          </div>
        </template>
      </el-row>
      <el-row type="flex" style="align-items: baseline;" justify="space-between">
        <el-row type="flex" style="align-items: baseline;">
          <span class="little-title">标签/关键信息：</span>
          <div class="scene-form-content">
            <ProductLabelKnowledge
              :selected-tag-list="memberTagInfo"
              :disabled-ids="disabledIds"
              @change="onTagChange"
            />
            <div v-if="isEdit">
              <el-button plain type="info" @click="chooseKnowledge">
                选择<svg-icon name="el-icon-plus" />
              </el-button>
            </div>
          </div>
        </el-row>
        <el-row v-if="isEdit" type="flex" style="align-items: baseline;">
          <span class="little-title">分类：</span>
          <ksh-select v-model="categoryId" clearable placeholder="选择分类">
            <el-option
              v-for="optionsListItem in optionsList"
              :key="optionsListItem.categoryId"
              :label="optionsListItem.title"
              :value="optionsListItem.categoryId"
            />
          </ksh-select>
        </el-row>
      </el-row>
      <el-row v-if="isEdit" type="flex" class="mgn-t10">
        <span v-if="isEdit || analysis" class="little-title">解析：</span>
        <ksh-input
          v-if="isEdit"
          v-model.trim="analysis"
          placeholder="请输入题目解析"
          maxlength="200"
          type="textarea"
          show-word-limit
          class="input-sze"
        />
        <span v-if="!isEdit && analysis">{{ analysis }}</span>
      </el-row>
      <el-row type="flex" class="editor">
        <span v-if="isEdit && showRichText" class="little-title">富文本：</span>
        <RichEditor
          v-if="isEdit && showRichText"
          v-model="examRichTextContent"
        />
      </el-row>
    </div>
    <div v-if="item.hasSet && isNeed" class="tips-w">* 此题设置了跳题逻辑</div>
    <el-row
      v-if="showAction"
      type="flex"
      justify="space-between"
      align="middle"
      class="opt-wrapper"
    >
      <el-row type="flex" align="middle" style="flex-wrap: wrap;">
        <el-row v-if="hasHover || showPop">
          <el-popover
            placement="bottom"
            trigger="click"
            :value="showPop"
            width="300px"
            @show="onShow"
            @hide="onHide"
          >
            <AddOtherExam @addExam="onAddExam" />
            <span slot="reference" class="text-primary-link">
              <i class="el-icon-plus font-weight" />
              <span class="mgn-l5">在此题后加入新题</span>
            </span>
          </el-popover>
          <span v-if="isEdit" class="text-primary-link mgn-l20" @click="onChangeRichText">
            <i v-if="!showRichText" class="el-icon-plus font-weight" />
            <span class="mgn-l5 add-rich-text" :class="{ active: showRichText }">
              {{ showRichText ? '删除题目描述（图文）' : '添加题目描述（图文）' }}
            </span>
          </span>
          <span
            v-if="showJump && isNeed"
            class="text-primary-link mgn-l20"
            @click.stop="onJump"
          >{{ item.hasSet ? '编辑跳题逻辑' : '设置跳题逻辑' }}</span>
        </el-row>
      </el-row>
      <el-row>
        <template v-if="isEdit">
          <el-button
            type="primary"
            plain
            class="w100"
            @click="onDel"
          >
            删除
          </el-button>
          <el-button type="primary" @click="onSave">完成编辑</el-button>
        </template>
        <exam-opt-action
          v-else-if="!item.hasSet"
          :show-move="isNeed"
          :show-edit="!item.quoted"
          @onDel="onDel"
          @onEdit="onEdit"
          @onMoveUp="onMoveUp"
          @onMoveDown="onMoveDown"
        />
      </el-row>
    </el-row>
    <ProductLabel
      :visible.sync="knowledgeDialog"
      :disabled-ids="disabledIds"
      :selected-tag-list="memberTagInfo"
      :options="{
        SPREAD_DEPARTMENT_GROUP: {
          isShowTab: false
        },
        CUSTOMIZE_TAG_GROUP: {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>
<script>
import AddOtherExam from '@/components/AddOtherExam'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
export default {
  name: 'ExamBlankFill',
  components: {
    AddOtherExam,
    ProductLabel,
    ProductLabelKnowledge
  },
  filters: {
    textFilter(text) {
      return text.replace(/<filter><\/filter>/g, '______ ')
    }
  },
  props: {
    showAction: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    showJump: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isNeed: {
      //  素材问卷不需要
      type: Boolean,
      default: false
    },
    optionsList: {
      type: Array,
      default: () => []
    },
    suitableType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      title: '',
      showPop: false,
      hasHover: false,
      message: '',
      showRichText: false,
      categoryId: '',
      examRichTextContent: '',
      knowledgeDialog: false,
      isOrder: false,
      examAnswerSettingBO: {},
      analysis: '',
      keyWord: [],
      inputValue: '',
      isSetCorrectAnswer: false,
      answers: [],
      examExpand: '',
      memberTagInfo: [],
      usedIds: [],
      isUsed: false,
      isPreSet: false,
      isExport: false,
      loading: false,
      inputSearchList: [],
      hasShowCorrectDia: false
    }
  },
  computed: {
    titleFilter() {
      return this.title.replace(/<filter><\/filter>/g, '______ ')
    },
    isEdit() {
      return !this.$props.item.save
    },
    hasImage() {
      return (
        [...(this.item.examResourceBOList || [])].filter(
          v => v.resourceType === 1
        ).length > 0
      )
    },
    fristImage() {
      return this.imageList[0] || ''
    },
    videoUrl() {
      const ot =
        [...(this.item.examResourceBOList || [])]
          .filter(v => v.resourceType === 2)
          .map(k => k.resource)[0] || {}
      return ot.url || ''
    },
    videoId() {
      const ot =
        [...(this.item.examResourceBOList || [])]
          .filter(v => v.resourceType === 2)
          .map(k => k.resource)[0] || {}
      return ot.videoId || ''
    },
    imageList() {
      return [...(this.item.examResourceBOList || [])]
        .filter(v => v.resourceType === 1)
        .map(k => k.resource.middle)
    },
    hasVideo() {
      return (
        [...(this.item.examResourceBOList || [])].filter(
          v => v.resourceType === 2
        ).length > 0
      )
    },
    disabledIds() {
      if (!this.isEdit) {
        const arr = []
        this.memberTagInfo?.map(v => {
          arr.push(v.tagId)
          v.knowledgePointInfos?.map(k => {
            arr.push(k.knowledgePointId)
          })
        })
        return arr
      } else {
        return this.isExport ? [] : this.usedIds
      }
    }
  },
  mounted() {
    this.title = this.item.title || ''
    this.categoryId = this.item.category ? this.item.category.categoryId : null
    this.examRichTextContent = this.item.examRichTextContent || ''
    this.memberTagInfo = this.item.tagInfos || []
    this.usedIds = this.item.tagInfos?.map(v => v.tagId)
    this.isSetCorrectAnswer = this.item.isSetCorrectAnswer || false
    this.isPreSet = this.item.isSetCorrectAnswer
    this.isUsed = !this.item.isRealCanDel
    this.showRichText = !!this.item.examRichTextContent
    this.examExpand = this.item.examExpand || ''
    this.analysis = this.item.analysis || ''
    this.examAnswerSettingBO = this.item.examAnswerSettingVO || {
      isInOrder: true,
      isIgnoreCase: true
    }
    this.isOrder = !this.examAnswerSettingBO.isInOrder || false
    this.isExport = this.item.isExport || false
    if (this.item.answers.length) {
      this.item.answers.map(v => {
        this.keyWord.push({
          value: v.title.split(','),
          isEditor: false,
          answerId: v.answerId
        })
      })
    } else {
      if (!this.title) {
        this.keyWord = [{ value: [], isEditor: false }]
      } else {
        this.title = this.title
          .replace(/______/g, '<filter></filter>')
          .replace(/,/g, '，')
          .replace(/\s*/g, '')
        const filterLength = this.title.split('<filter></filter>').length - 1
        const num = filterLength - this.keyWord.length
        for (let i = 0; i < num; i++) {
          this.keyWord.push({ value: [], isEditor: false })
        }
      }
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      if (!queryString) return []
      this.loading = true
      this.$axios.get(this.$API.fuzzyMatchList, {params: {searchKey: queryString, answerType: 2}}).then(
        res => {
          this.loading = false
          this.inputSearchList = res.data || []
          const results = this.inputSearchList.map(v => {
            return {...v, value: v.title.replace(/<filter><\/filter>/g, '______ ')}
          })
          cb(results)
        },
        (rea => {
          this.loading = false
          this.$message.error(rea.message)
        })
      )
    },
    handleSelect(item) {
      this.title = item.value
      this.$emit('onTiggerTitle', { ...item, id: this.item.id })
    },
    onSubmitTag(selectedList) {
      this.knowledgeDialog = false
      this.memberTagInfo = [...selectedList]
    },
    onTagChange(selectedList) {
      this.memberTagInfo = [...selectedList]
    },
    handleClose(v, tag) {
      v.value.splice(v.value.indexOf(tag), 1)
    },
    showInput(v, index) {
      v.isEditor = true
      this.$nextTick(() => {
        const ind = `saveTagInput${index}`
        this.$refs[ind][0].$refs.input.focus()
      })
    },
    handleInputConfirm(v) {
      const inputValue = this.inputValue
      if (inputValue) {
        v.value.push(inputValue)
      }
      v.isEditor = false
      this.inputValue = ''
    },
    onChange(e) {
      this.title = e.replace(/______/g, '<filter></filter>')
    },
    onAddFill() {
      this.title = `${this.title}<filter></filter>`
      this.onBlur()
    },
    onAddExam(type) {
      this.showPop = false
      this.$emit('onAddExam', type)
    },
    onJump() {
      this.$emit('onJump')
    },
    onMouseOver() {
      this.hasHover = true
    },
    onMouseOut() {
      this.hasHover = false
    },
    onShow() {
      this.showPop = true
    },
    onHide() {
      this.showPop = false
    },
    showMessage(msg) {
      this.$message.error(msg)
    },
    chooseKnowledge() {
      this.knowledgeDialog = true
    },
    onSave() {
      if (!this.title) {
        this.showMessage('请输入题目内容')
        return
      }
      if (this.title.indexOf('<filter></filter>') < 0) {
        this.showMessage('至少添加一个填空符')
        return
      }
      if (this.memberTagInfo.length === 0) {
        this.showMessage('请选择标签/关键信息')
        return false
      }
      this.answers = []
      this.keyWord.map(v => {
        if (v.value.length) {
          this.answers.push({
            title: v.value.join(','),
            isCorrect: true,
            answerId: v.answerId || null
          })
        }
      })
      this.isSetCorrectAnswer = this.answers.length > 0
      // 如果没有设置推荐/正确选项，提示是否设置
      if (!this.isSetCorrectAnswer && !this.hasShowCorrectDia) {
        this.$MessageDialog({
          title: '温馨提示',
          message: '<div style="padding: 20px;font-size: 14px;">为了收集更全面、有价值的数据，建议您为问卷每题设定<span style="color: #3D61E3;">推荐/正确选项</span>。这将助力深入分析，让调研结果更精准、有意义。</div>',
          submitText: '我知道了',
          cancelText: ''
        })
          .then(() => {
            this.hasShowCorrectDia = true
            return true
          })
        return false
      }
      this.examRichTextContent = this.showRichText
        ? this.examRichTextContent
        : ''
      this.examAnswerSettingBO.isInOrder = !this.isOrder
      this.$emit('onTigger', {
        ...this.item,
        title: this.title,
        examAnswerSettingBO: this.examAnswerSettingBO,
        suitableType: this.suitableType,
        answers: this.answers,
        analysis: this.analysis,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        examRichTextContent: this.showRichText
          ? this.examRichTextContent
          : '',
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: true
      })
    },
    onDel() {
      if (this.item.hasSet) {
        this.$emit(
          'onValid',
          res => {
            if (res) {
              this.$emit('onDel')
            }
          },
          this.item.examId
        )
        return
      }
      this.$emit('onDel')
    },
    onEdit() {
      if (this.item.hasSet) {
        this.$emit(
          'onValid',
          () => {
            this.$emit('onSave', {
              ...this.item,
              title: this.title,
              examAnswerSettingBO: this.examAnswerSettingBO,
              suitableType: this.suitableType,
              answers: this.keyWord,
              analysis: this.analysis,
              memberTagInfo: this.memberTagInfo,
              categoryId: this.categoryId,
              examRichTextContent: this.showRichText
                ? this.examRichTextContent
                : '',
              isSetCorrectAnswer: this.isSetCorrectAnswer,
              save: false
            })
          },
          this.item.examId
        )
        return
      }
      this.$emit('onSave', {
        ...this.item,
        title: this.title,
        examAnswerSettingBO: this.examAnswerSettingBO,
        suitableType: this.suitableType,
        answers: this.keyWord,
        analysis: this.analysis,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        examRichTextContent: this.showRichText
          ? this.examRichTextContent
          : '',
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: false
      })
    },
    onMoveUp() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveUp')
        })
        return
      }
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveDown')
        })
        return
      }
      this.$emit('onMoveDown')
    },
    onBlur() {
      if (!this.title) {
        return
      }
      this.title = this.title
        .replace(/______/g, '<filter></filter>')
        .replace(/,/g, '，')
        .replace(/\s*/g, '')
      const filterLength = this.title.split('<filter></filter>').length - 1
      if (filterLength >= this.keyWord.length) {
        for (let i = 0; i < filterLength - this.keyWord.length; i++) {
          this.keyWord.push({ value: [], isEditor: false })
        }
      } else {
        for (let i = 0; i < this.keyWord.length - filterLength; i++) {
          this.keyWord.pop()
        }
      }
      this.$emit('onTigger', { ...this.item, title: this.title })
    },
    onChangeRichText() {
      this.examRichTextContent = ''
      this.showRichText = !this.showRichText
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-wrapper {
  position: relative;
  padding-top: 20px;
  padding-bottom: 75px;
}
.exam-blank-fill-wrapper {
  min-height: 200px;
  padding: 12px 15px;
  background: #fff;
  flex: 1;
}
.input-sze ::v-deep {
  .el-textarea__inner {
    padding: 12px 15px;
  }
}
.tips-w {
  padding-top: 5px;
  color: #bbb;
}
.opt-wrapper {
  position: absolute;
  height: 40px;
  bottom: 20px;
  left: 0;
  right: 0;
}
.alert-message {
  z-index: 1000;
  margin-bottom: 10px;
}
.active-btn {
  color: rgba(25, 140, 255, 1);
  text-decoration: underline;
  position: relative;
  .image-preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.add-rich-text {
  &.active {
    color: #e8312f;
  }
}
.editor {
  margin-top: 10px;
  width: 100%;
  line-height: initial;
}
.little-title {
  color: #5a5a5a;
  width: 110px;
  text-align: right;
  flex: none;
}
.button-default ::v-deep {
  margin-top: 10px;
  .el-button--default {
    border: 1px solid #dcdfe6;
    color: #606266;
  }
}
.choose-again {
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
  color: #3d61e3;
}
.rich-content {
  word-break: break-all;
  ::v-deep {
    img {
      width: 300px;
    }
  }
}
.setting {
  font-size: 12px;
  color: #5a5a5a;
  margin-right: 5px;
}
.tips {
  font-size: 12px;
  color: #969696;
  margin-left: 10px;
}
.word-border {
  border: 1px solid #e1e1e1;
  padding: 5px 15px;
  width: 100%;
  margin-bottom: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  height: 24px;
  line-height: 24px;
  margin-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  color: #5a5a5a;
  border-color: #e1e1e1;
  background: #fbfbfb;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
