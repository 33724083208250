<template>
  <div class="exam-wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <el-row type="flex" class="editor">
      <RichEditor v-if="!item.save" v-model="richTextContent" />
      <div v-show="item.save" v-safe-html="item.richTextContent" />
    </el-row>
    <el-row
      v-if="showAction"
      type="flex"
      justify="space-between"
      align="middle"
      class="opt-wrapper"
    >
      <el-row type="flex" align="middle">
        <el-row v-if="hasHover || showPop">
          <el-popover
            placement="bottom"
            trigger="click"
            :value="showPop"
            @show="onShow"
            @hide="onHide"
          >
            <AddOtherExam @addExam="onAddExam" />
            <span slot="reference" class="text-primary-link">
              <i class="el-icon-plus font-weight" />
              <span class="mgn-l5">在此题后加入新题</span>
            </span>
          </el-popover>
        </el-row>
      </el-row>
      <el-row>
        <el-row v-if="isEdit">
          <el-button
            type="primary"
            plain
            @click="onDel"
          >
            删除
          </el-button>
          <el-button type="primary" @click="onSave">完成编辑</el-button>
        </el-row>
        <exam-opt-action
          v-else-if="!item.hasSet"
          :show-edit="!item.quoted"
          @onDel="onDel"
          @onEdit="onEdit"
          @onMoveUp="onMoveUp"
          @onMoveDown="onMoveDown"
        />
      </el-row>
    </el-row>
  </div>
</template>
<script>
import AddOtherExam from '@/components/AddOtherExam'
export default {
  name: 'ExamRichText',
  components: {
    AddOtherExam
  },
  props: {
    showAction: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      title: '',
      hasHover: false,
      showPop: false,
      message: '',
      richTextContent: ''
    }
  },
  computed: {
    isEdit() {
      return !this.item.save
    }
  },
  mounted() {
    this.title = this.item.title || ''
    this.richTextContent = this.item.richTextContent || ''
  },
  methods: {
    onAddExam(type) {
      this.showPop = false
      this.$emit('onAddExam', type)
    },
    onJump() {
      this.$emit('onJump')
    },
    onMouseOver() {
      this.hasHover = true
    },
    onMouseOut() {
      this.hasHover = false
    },
    onShow() {
      this.showPop = true
    },
    onHide() {
      this.showPop = false
    },
    showMessage(msg) {
      this.$message.error(msg)
    },
    onSave() {
      if (this.richTextContent === '') {
        this.showMessage('请输入内容')
        return
      }
      this.$emit('onSave', {...this.item, richTextContent: this.richTextContent, save: true}, this.index - 1)
    },
    onDel() {
      this.$emit('onDel')
    },
    onEdit() {
      this.$emit('onSave', {...this.item, save: false}, this.index - 1)
    },
    onMoveUp() {
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      this.$emit('onMoveDown')
    },
    beforeDestroy() {

    }
  }
}
</script>
<style lang="scss" scoped>
  .exam-wrapper {
    position: relative;
    padding-top: 20px;
    padding-bottom: 75px;
  }
  .tips-w {
    padding-top: 5px;
    color: #bbb;
  }
  .opt-wrapper {
    position: absolute;
    height: 40px;
    bottom: 20px;
    left: 0;
    right: 0;
  }
  .alert-message {
    z-index: 1000;
    margin-bottom: 10px;
  }
  .active-btn {
    color: rgba(25, 140, 255, 1);
    text-decoration: underline;
    position: relative;
    .image-preview {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
</style>
